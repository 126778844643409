import request from '@/utils/request'

export function getCodeList(params) {
  return request({
    url: '/user/codeList',
    method: 'get',
    params,
  })
}

export function getOrderList() {
  return request({
    url: '/user/orderList',
    method: 'get',
  })
}

/**
 * 获取拨打隐私电话
 */
export function doCallPhone(data) {
  return request({
    url: '/user/callphone',
    method: 'post',
    data,
  })
}

/**
 * 发送挪车短信
 */
export function doSendSms(data) {
  return request({
    url: '/user/sendSms',
    method: 'post',
    data,
  })
}

/**
 * 执行授权
 */
export function doAlipayAuth(data) {
  return request({
    url: '/user/alipayAuth',
    method: 'post',
    data,
  })
}

/**
 * 检查用户授权
 */
export function getCheckAuth() {
  return request({
    url: '/user/checkAuth',
    method: 'get',
  })
}

/**
 * 拨打扣费
 */
export function doPayCall(data) {
  return request({
    url: '/user/payCall',
    method: 'post',
    data,
  })
}

/**
 * 获取联系费用
 */
export function getContactPrice() {
  return request({
    url: '/user/contactPrice',
    method: 'get',
  })
}

/**
 * 获取押金金额
 */
export function getDepositAmount() {
  return request({
    url: '/user/depositAmount',
    method: 'get',
  })
}

/**
 * 支付押金
 */
export function doPayDeposit(data) {
  return request({
    url: '/user/payDeposit',
    method: 'post',
    data,
  })
}
